<template>
	<form>
		<div class="row">
			<div class="col">
				<base-input :error="getError('facebook_url')" label="Facebook" v-model="facebook"></base-input>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<base-input :error="getError('twitter_url')" label="Twitter" v-model="twitter"></base-input>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<base-input :error="getError('instagram_url')" label="Instagram" v-model="instagram"></base-input>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<base-input :error="getError('snapchat')" label="Snapchat" v-model="snapchat"></base-input>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<base-button :type="'primary'" @click="save">{{ getTrans("messages.save") }}
				</base-button>
			</div>
		</div>
	</form>
</template>
<script>
export default {
  emits: ['save'],
	props: {
		user: Object,
		getError: Function,
	},
	data() {
		return {
			facebook: "",
			twitter: "",
			instagram: "",
			snapchat: "",
		};
	},
	methods: {
		save() {
			let user = {};
			if (this.facebook !== "") {
				user.facebook_url = this.facebook;
			}
			if (this.twitter !== "") {
				user.twitter_url = this.twitter;
			}
			if (this.instagram !== "") {
				user.instagram_url = this.instagram;
			}
			if (this.snapchat !== "") {
				user.snapchat = this.snapchat;
			}
			this.$emit("save", user);
		},
	},
	mounted() {
		if (this.user) {
			this.facebook = this.user.facebook_url ?
				this.user.facebook_url :
				"";
			this.twitter = this.user.twitter_url ? this.user.twitter_url : "";
			this.instagram = this.user.instagram_url ?
				this.user.instagram_url :
				"";
			this.snapchat = this.user.snapchat ? this.user.snapchat : "";
		}
	},
};
</script>
